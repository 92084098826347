import React,{useEffect} from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useCart } from '../context/CartContext'; // Import the CartContext

const OrderConfirmation = () => {
  const location = useLocation();
  const { orderDetails } = location.state || {};
  const { clearCart } = useCart(); // Use clearCart from context
  // Retrieve order details from state
  useEffect(() => {
    clearCart(); // Call the clearCart function from context
  }, [clearCart]);

  return (
    <div className="container padding-con">
    <br/>
      <div className="che-con">
        {orderDetails ? (
          <>
          
            <center>
              <h1>Thank You for Your Order!</h1>
              <h4>Your order id  is: {orderDetails.orderId}</h4>
              <p>Your order has been placed successfully.</p>
            

            </center>
            <h5>Order Summary:</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Price (₹)</th>
                  <th>Total (₹)</th>
                </tr>
              </thead>
              <tbody>
                {orderDetails.items.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.quantity}</td>
                    <td>{item.price}</td>
                    <td>{(item.quantity * item.price).toFixed(2)}</td> {/* Calculate total for each item */}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="3" className="text-right"><strong>Total Value:</strong></td>
                  <td><strong>₹{orderDetails.totalValue.toFixed(2)}</strong></td>
                </tr>
                <tr>
                  <td colSpan="3" className="text-right"><strong>Shipping Charges:</strong></td>
                  <td><strong>₹{orderDetails.shippingCharge.toFixed(2)}</strong></td>
                </tr>
                <tr>
                  <td colSpan="3" className="text-right"><strong>Grand Total:</strong></td>
                  <td><strong>₹{orderDetails.grandTotal.toFixed(2)}</strong></td>
                </tr>
              </tfoot>
            </table>
            <h5>Shipping Address:</h5>
            <p>{orderDetails.shippingAddress.fullName}</p>
            <p>{orderDetails.shippingAddress.addressLine1}</p>
            <p>{orderDetails.shippingAddress.addressLine2}</p>
            <p>{orderDetails.shippingAddress.city}, {orderDetails.shippingAddress.state}, {orderDetails.shippingAddress.postalCode}, {orderDetails.shippingAddress.country}</p>
            <p>Phone: {orderDetails.shippingAddress.phone}</p>
            <Link to="/" className="btn btn-success">Go to Home</Link>
          </>
        ) : (
          <p>No order details available. Please try again.</p>
        )}
      </div>
      <br />
    </div>
  );
};

export default OrderConfirmation;
