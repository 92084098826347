// src/FireBase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCCNZ3uNI0C8-ZaCkmzucV7cQUl24xUQwI",
  authDomain: "narmadha-publications.firebaseapp.com",
  databaseURL: "https://narmadha-publications-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "narmadha-publications",
  storageBucket: "narmadha-publications.appspot.com",
  messagingSenderId: "649226969900",
  appId: "1:649226969900:web:cda8d02f4cc495a51a8289",
  measurementId: "G-C3ZX4DDQG8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth and Firestore
export const auth = getAuth(app);
export const db = getFirestore(app);
