// src/components/LogoutButton.js
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const LogoutButton = () => {
  const { logout } = useContext(AuthContext); // Access the logout function from AuthContext
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleLogout = () => {
    // Optional: Confirm logout action with the user
    const confirmLogout = window.confirm('Are you sure you want to logout?');
    if (!confirmLogout) return;

    // Call the logout function from AuthContext to clear auth state and localStorage
    logout();

    // Optionally, clear other user-related data if necessary
    // localStorage.removeItem('addresses');
    // localStorage.removeItem('orders');
    // Add any other cleanup logic here

    // Redirect the user to the login page or home page after logout
    navigate('/account'); // Adjust the path as needed (e.g., '/login' or '/')
  };

  return (
      
      <Button variant="danger" onClick={handleLogout}>
        Logout
      </Button>
    
  );
};

export default LogoutButton;
