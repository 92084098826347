import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Offcanvas, Container, Badge, Dropdown } from 'react-bootstrap';
import { FaSearch, FaShoppingBag, FaUser, FaBars, FaShoppingBasket } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Header.css';
import { CartContext } from '../context/CartContext';

const MyNavbar = () => {
  const { cartItemCount } = useContext(CartContext);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en'); // Default to English
  
  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    localStorage.setItem('language', lang); // Save selected language to localStorage
    window.location.reload();
  };

  return (
    <>
      <Navbar style={{backgroundColor:'#023e6f'}} expand="lg" sticky="top" className="shadow-sm">
        <Container>
          <button className="btn d-lg-none me-2" onClick={handleShow} aria-label="Toggle navigation">
            <FaBars style={{ color: 'white' }} size={20} />
          </button>

          <Navbar.Brand as={Link} to="/" className="flex-grow-1" style={{ color: 'white' }}>
            Narmadha Publications
          </Navbar.Brand>
           {/* Language Switcher for Mobile Menu */}
           <Dropdown className="my-3 lan-sw dropstart">
            <Dropdown.Toggle className="btn-sm lang-dropdown" variant="outline-light" id="mobile-lang-dropdown">
              {language === 'en' ? 'English' : 'தமிழ்'}
            </Dropdown.Toggle>

            <Dropdown.Menu className="lang-dropdown-menu">
              <Dropdown.Item onClick={() => handleLanguageChange('en')}>English</Dropdown.Item>
              <Dropdown.Item onClick={() => handleLanguageChange('ta')}>தமிழ்</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>


          <div className="d-none d-lg-flex align-items-center">
            <Nav.Link as={Link} to="/" className="d-flex align-items-center me-3 nav-link-own">Home</Nav.Link>
            <Nav.Link as={Link} to="/shop" className="d-flex align-items-center me-3 nav-link-own">Shop</Nav.Link>
            <Nav.Link as={Link} to="/about" className="d-flex align-items-center me-3 nav-link-own">About</Nav.Link>
            <Nav.Link as={Link} to="/contact" className="d-flex align-items-center me-3 nav-link-own">Contact</Nav.Link>

            <Nav.Link as={Link} to="/search" className="d-flex align-items-center me-3 nav-link-own">
              <FaSearch size={18} />
            </Nav.Link>
            <Nav.Link as={Link} to="/account" className="d-flex align-items-center me-3 nav-link-own">
              <FaUser size={18} />
            </Nav.Link>
            <Nav.Link as={Link} to="/cart" className="d-flex align-items-center position-relative nav-link-own">
              <FaShoppingBag size={18} />
              {cartItemCount > 0 && (
                <Badge
                  pill
                  bg="success"
                  className="position-absolute top-0 start-100 translate-middle"
                  style={{ fontSize: '0.6rem' }}
                >
                  {cartItemCount}
                </Badge>
              )}
            </Nav.Link>

            {/* Language Switcher Dropdown */}
            <Dropdown className="ms-3">
              <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
                {language === 'en' ? 'English' : 'தமிழ்'}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleLanguageChange('en')}>English</Dropdown.Item>
                <Dropdown.Item onClick={() => handleLanguageChange('ta')}>தமிழ்</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Container>
      </Navbar>

      {/* Offcanvas for Mobile Menu */}
      <Offcanvas show={showOffcanvas} onHide={handleClose} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Narmadha Publications</Offcanvas.Title>
          
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link className="nav-link" as={Link} to="/" onClick={handleClose}>Home</Nav.Link>
            <Nav.Link as={Link} to="/about" onClick={handleClose}>About</Nav.Link>
            <Nav.Link as={Link} to="/contact" onClick={handleClose}>Contact</Nav.Link>

           
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Icons for Mobile */}
      <div className="d-lg-none fixed-bottom shadow-sm w-100 d-flex justify-content-around py-2 mob-own-nav mob-nav-cus">
        <Nav.Link as={Link} to="/shop" className="d-flex flex-column align-items-center nav-icon mob-own-nav-link">
          <FaShoppingBasket size={20} />
          <small>Shop</small>
        </Nav.Link>
        <Nav.Link as={Link} to="/search" className="d-flex flex-column align-items-center mob-own-nav-link">
          <FaSearch size={20} />
          <small>Search</small>
        </Nav.Link>
        <Nav.Link as={Link} to="/account" className="d-flex flex-column align-items-center mob-own-nav-link">
          <FaUser size={20} />
          <small>Account</small>
        </Nav.Link>
        <Nav.Link as={Link} to="/cart" className="d-flex flex-column align-items-center position-relative mob-own-nav-link">
          <FaShoppingBag size={20} />
          {cartItemCount > 0 && (
            <Badge
              pill
              bg="success"
              className="position-absolute top-0 start-50 translate-middle"
              style={{ fontSize: '0.6rem' }}
            >
              {cartItemCount}
            </Badge>
          )}
          <small>Bag</small>
        </Nav.Link>
      </div>
    </>
  );
};

export default MyNavbar;
