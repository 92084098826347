import React, { useState, useContext, useEffect } from 'react';
import { Form, Button, Alert, Col, Row } from 'react-bootstrap';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext
import { db } from '../FireBase'; // Firestore instance
import { updateDoc, doc, getDoc } from 'firebase/firestore'; // Firestore methods
import { updatePassword } from 'firebase/auth'; // Firebase Auth

const AccountInfo = () => {
  const { currentUser, login } = useContext(AuthContext); // Access currentUser and login function from AuthContext
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch user data from Firestore
  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const docRef = doc(db, 'users', currentUser.uid); // Reference to the user's document
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          setFormData({
            name: userData.name || '',
            email: currentUser.email || '',
            phone: userData.phone || '',
            addressLine1: userData.address?.line1 || '',
            addressLine2: userData.address?.line2 || '',
            city: userData.address?.city || '',
            state: userData.address?.state || '',
            postalCode: userData.address?.postalCode || '',
            country: userData.address?.country || '',
          });
        }
      }
    };
    fetchUserData();
  }, [currentUser]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    const { name, email, phone, addressLine1, addressLine2, city, state, postalCode, country } = formData;

    // Basic validation
    if (!name.trim()) {
      setError('Name cannot be empty.');
      return;
    }
    if (!addressLine1 || !city || !state || !postalCode || !country) {
      setError('All address fields are required.');
      return;
    }

    try {
      setLoading(true);

      // Update user data in Firestore
      const userDocRef = doc(db, 'users', currentUser.uid); // Reference to user's Firestore doc
      await updateDoc(userDocRef, {
        name,
        phone,
        address: {
          line1: addressLine1,
          line2: addressLine2,
          city,
          state,
          postalCode,
          country,
        },
      });

      // Update AuthContext with new user data
      login({ ...currentUser, name, address: { line1: addressLine1, line2: addressLine2, city, state, postalCode, country } });

      setSuccess('Account details and address updated successfully!');
    } catch (err) {
      setError('Failed to update account details: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm={12} lg={6} md={6}>
            <Form.Group controlId="formName" className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleInputChange}
                required
                style={{ width: '100%' }}
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleInputChange}
                required
                style={{ width: '100%' }}
                disabled // Email should not be editable
              />
            </Form.Group>

            <Form.Group controlId="formPhone" className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                placeholder="Enter your phone"
                value={formData.phone}
                onChange={handleInputChange}
                style={{ width: '100%' }}
              />
            </Form.Group>
          </Col>
          <Col sm={12} lg={6} md={6}>
            {/* Address Fields */}
            <Form.Group controlId="formAddressLine1" className="mb-3">
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control
                type="text"
                name="addressLine1"
                placeholder="Enter address line 1"
                value={formData.addressLine1}
                onChange={handleInputChange}
                required
                style={{ width: '100%' }}
              />
            </Form.Group>

            <Form.Group controlId="formAddressLine2" className="mb-3">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                name="addressLine2"
                placeholder="Enter address line 2 (optional)"
                value={formData.addressLine2}
                onChange={handleInputChange}
                style={{ width: '100%' }}
              />
            </Form.Group>

            <Form.Group controlId="formCity" className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                placeholder="Enter city"
                value={formData.city}
                onChange={handleInputChange}
                required
                style={{ width: '100%' }}
              />
            </Form.Group>

            <Form.Group controlId="formState" className="mb-3">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                name="state"
                placeholder="Enter state"
                value={formData.state}
                onChange={handleInputChange}
                required
                style={{ width: '100%' }}
              />
            </Form.Group>

            <Form.Group controlId="formPostalCode" className="mb-3">
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                type="text"
                name="postalCode"
                placeholder="Enter postal code"
                value={formData.postalCode}
                onChange={handleInputChange}
                required
                style={{ width: '100%' }}
              />
            </Form.Group>

            <Form.Group controlId="formCountry" className="mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                name="country"
                placeholder="Enter country"
                value={formData.country}
                onChange={handleInputChange}
                required
                style={{ width: '100%' }}
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? 'Updating...' : 'Update Details'}
            </Button>
          </Col>
        </Row>
      </Form>
      <br />
    </>
  );
};

export default AccountInfo;
