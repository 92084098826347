// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from '../FireBase'; // Import Firebase
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore'; // Import getDoc to fetch user data

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [alert, setAlert] = useState(''); // State for error messages

  const clearError = () => {
    setAlert('');
  };
  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = { uid: user.uid, email: user.email, ...userDocSnap.data() };
          setCurrentUser(userData);
          setAlert(''); // Clear error if logged in
        } else {
          console.error("No user data found in Firestore.");
        }
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const register = async ({ email, password, name, phone }) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, 'users', user.uid), {
        name,
        email,
        phone,
        uid: user.uid,
      });

      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      const userData = { uid: user.uid, email: user.email, ...userDocSnap.data() };

      setCurrentUser(userData);
      setAlert(''); // Clear error on successful registration

    } catch (error) {
      setAlert(error.message); // Set error message
      console.error("Error during registration:", error.message);
      setTimeout(clearError, 3000);
    }
  };

  const login = async ({ email, password }) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = { uid: user.uid, email: user.email, ...userDocSnap.data() };
        setCurrentUser(userData);
        setAlert(''); // Clear error on successful login
      } else {
        console.error("No user data found in Firestore.");
      }
    } catch (error) {
      setAlert("invalid-credential"); // Set error message
      console.error("Error during login:", error.message);
      setTimeout(clearError, 3000);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
      setAlert(''); // Clear error on logout
    } catch (error) {
      setAlert(error.message); // Set error message
      console.error("Error during logout:", error.message);
      setTimeout(clearError, 3000);
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, login, register, logout, alert }}>
      {children}
    </AuthContext.Provider>
  );
};
