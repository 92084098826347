import React, { useContext,useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../css/ProductCard.css'; // CSS for hover effect
import { CartContext } from '../context/CartContext';
import { useAlert } from '../context/AlertContext';
import { FaShoppingBag } from 'react-icons/fa';



const ProductCard = ({ id, image, categoryName, productName, price, stock, sku }) => {
  const [language] = useState(localStorage.getItem('language') || 'en'); // Default to English
  
  const { addToCart } = useContext(CartContext); // Use the addToCart function from the context
  const { triggerAlert } = useAlert();
  const fontSizeClass = language === 'ta' ? 'font-size-tamil' : 'font-size-default';
  const headSizeClass = language === 'ta' ? 'head-size-tamil' : 'head-size-default';

  const handleAddToCart = () => {
    

    // Call addToCart with the product details
    addToCart({
      customId: id,             // unique identifier for the product (use id passed in props)
      name: productName,        // product name
      price: price,             // product price
      sku: sku,                 // product SKU (add it in the props if needed)
      productImage: image,      // product image
    });
    console.log(`Added product ${productName}${sku} to cart`);
    triggerAlert('Book added to your bag!', 'success');
  };

  return (
    <Card className="product-card">
      <Link to={`/product/${id}`} className="product-link">
        <div className="image-container">
          <Card.Img variant="top" src={image} alt={productName} />
          {/* Show "Out of Stock" overlay if stock is 'OutOfStock' */}
          {stock === 'OutOfStock' && (
            <div className="out-of-stock-overlay">
              <span>Out of Stock</span>
            </div>
          )}
        </div>
      </Link>
      <Card.Body>
        <Link to={`/product/${id}`} className="product-link">
          <Card.Title className={headSizeClass}>{productName}</Card.Title>
          <Card.Text className={fontSizeClass}>{categoryName}</Card.Text>
          <Card.Text>₹{price}</Card.Text>
        </Link>

        {/* Disable Add to Cart button if stock is 'OutOfStock' */}
        <button
          className="add-to-cart-btn"
          disabled={stock === 'OutOfStock'}
          onClick={handleAddToCart}
          style={{
            backgroundColor: stock === 'OutOfStock' ? 'grey' : '#007bff',
            cursor: stock === 'OutOfStock' ? 'not-allowed' : 'pointer',
            display: 'flex',          // Display flex for alignment
            alignItems: 'center',     // Vertically center icon and text
            justifyContent: 'center', // Horizontally center content
          }}
        >
          {stock === 'OutOfStock' ? 'Out of Stock' : (
            <>
              <FaShoppingBag style={{ marginRight: '8px' }} /> {/* Bag Icon */}
              Add to Bag
            </>
          )}
        </button>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
