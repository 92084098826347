import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { db } from '../FireBase'; // Import your Firebase config
import { collection, getDocs } from 'firebase/firestore'; // Import Firestore functions
import '../css/CategoryList.css';

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const [language] = useState(localStorage.getItem('language') || 'en'); // Default to English
 

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesCollection = collection(db, 'categories'); // Adjust the collection name if needed
        const categorySnapshot = await getDocs(categoriesCollection);
        const categoryList = categorySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoryList);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (categoryId) => {
    navigate(`/shop/${categoryId}`); // Pass category ID in the URL
  };

  return (
    <div style={{ maxHeight: '500px', overflowY: 'scroll', border: '1px solid #ddd', padding: '10px' }}>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {categories.map((category) => (
          <li key={category.id} style={{ marginBottom: '8px' }}>
            <button 
              className='filter-btn-home' 
              variant='outline-primary' 
              onClick={() => handleCategoryClick(category.id)} 
              style={{ cursor: 'pointer' }}
            >
              {language === 'en' ? category.nameEn : category.nameTa}
            
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryList;
