// src/pages/ShopPage.js
import React, { useContext } from 'react';

import SearchComponent from '../components/SearchComponent';

const SearchPage = () => {


  return (
    <>
    <SearchComponent/>
    </>
  );
};

export default SearchPage;