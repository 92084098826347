// src/pages/AccountPage.js
import React, { useContext } from 'react';
import AuthForm from '../components/AuthForm';
import AccountDetails from '../components/AccountDetails';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext
import { Button } from 'react-bootstrap'; // Import Button from react-bootstrap

const AccountPage = () => {
  const { currentUser } = useContext(AuthContext); // Destructure currentUser and logout from context

  return (
    <>
      {currentUser ? (
        <>
          <AccountDetails />
        
        </>
      ) : (
        <AuthForm />
      )}
    </>
  );
};

export default AccountPage;
