const product = [
    {
      id: 1,
      image: 'https://th.bing.com/th/id/OIP.B_KN-E8TxiLabTyzaL4B9gHaK-?w=205&h=304&c=7&r=0&o=5&dpr=1.3&pid=1.7', // Replace with your image path
      categoryName: 'Tamil Novel',
      subCategoryName: 'Fiction',
      productName: 'Book one',
      price: 50,
      quantity: 1,
      SKU:"123A11",
      author:"krish",
      short_description:"A thought-provoking novel about choices, regrets, and second chances, set in a magical library between life and death.",
      long_description:"Set in the 1930s Deep South, To Kill a Mockingbird explores themes of racial inequality, moral integrity, and the innocence of childhood through the eyes of Scout Finch, a young girl growing up in Maycomb, Alabama. The novel follows Scout, her brother Jem, and their father, Atticus Finch, a lawyer tasked with defending Tom Robinson, a Black man falsely accused of raping a white woman. Through Scout’s narrative, the story delves into the complex social dynamics of a racially divided community, questioning the meaning of justice, compassion, and human decency. Harper Lee’s masterpiece remains a powerful commentary on the systemic racism that continues to echo through modern times."
    },
    {
      id: 2,
      image: 'https://th.bing.com/th/id/OIP.WDp_38LGnYKjC8ad3PRIoAAAAA?w=207&h=300&c=7&r=0&o=5&dpr=1.3&pid=1.7',
      categoryName: 'Tamil Novel',
      subCategoryName: 'non-Fiction',
      productName: 'Book Two',
      price: 200,
      quantity: 1,
      SKU:"123A11",
      author:"krish",
      short_description:"A thought-provoking novel about choices, regrets, and second chances, set in a magical library between life and death."
      ,
      long_description:"Set in the 1930s Deep South, To Kill a Mockingbird explores themes of racial inequality, moral integrity, and the innocence of childhood through the eyes of Scout Finch, a young girl growing up in Maycomb, Alabama. The novel follows Scout, her brother Jem, and their father, Atticus Finch, a lawyer tasked with defending Tom Robinson, a Black man falsely accused of raping a white woman. Through Scout’s narrative, the story delves into the complex social dynamics of a racially divided community, questioning the meaning of justice, compassion, and human decency. Harper Lee’s masterpiece remains a powerful commentary on the systemic racism that continues to echo through modern times."
  
    },
    {
      id: 3,
      image: 'https://th.bing.com/th/id/OIP.OoAl690Iup8yqMEjOSHdWgHaJv?w=208&h=274&c=7&r=0&o=5&dpr=1.3&pid=1.7',
      categoryName: 'English Novel',
      subCategoryName: 'Sci-fi',
      productName: 'Book Three',
      price: 349,
      quantity: 1,
      SKU:"123A22",
      author:"Kannan",
      short_description:"A thought-provoking novel about choices, regrets, and second chances, set in a magical library between life and death."
      ,
      long_description:"Set in the 1930s Deep South, To Kill a Mockingbird explores themes of racial inequality, moral integrity, and the innocence of childhood through the eyes of Scout Finch, a young girl growing up in Maycomb, Alabama. The novel follows Scout, her brother Jem, and their father, Atticus Finch, a lawyer tasked with defending Tom Robinson, a Black man falsely accused of raping a white woman. Through Scout’s narrative, the story delves into the complex social dynamics of a racially divided community, questioning the meaning of justice, compassion, and human decency. Harper Lee’s masterpiece remains a powerful commentary on the systemic racism that continues to echo through modern times."
  
    },
    {
      id: 4,
      image: 'https://th.bing.com/th/id/OIP.yDePIbO4Lg1Imb8OspF-ggHaLM?w=203&h=307&c=7&r=0&o=5&dpr=1.3&pid=1.7',
      categoryName: 'English Novel',
      subCategoryName: 'Crime',
      productName: 'Book Four',
      price: 500,
      quantity: 1,
      SKU:"123A33",
      author:"Kishor", 
      short_description:"A thought-provoking novel about choices, regrets, and second chances, set in a magical library between life and death."
      ,
      long_description:"Set in the 1930s Deep South, To Kill a Mockingbird explores themes of racial inequality, moral integrity, and the innocence of childhood through the eyes of Scout Finch, a young girl growing up in Maycomb, Alabama. The novel follows Scout, her brother Jem, and their father, Atticus Finch, a lawyer tasked with defending Tom Robinson, a Black man falsely accused of raping a white woman. Through Scout’s narrative, the story delves into the complex social dynamics of a racially divided community, questioning the meaning of justice, compassion, and human decency. Harper Lee’s masterpiece remains a powerful commentary on the systemic racism that continues to echo through modern times."
  
    },
    {
      id: 5,
      image: 'https://th.bing.com/th/id/OIP.jE5aC4Yu98tsWhb8klAuNwHaLL?w=203&h=307&c=7&r=0&o=5&dpr=1.3&pid=1.7',
      categoryName: 'English novel',
      subCategoryName: 'Fiction',
      productName: 'Book five',
      price: 140,
      quantity: 1,
      SKU:"123A44",
      author:"Leo", short_description:"A thought-provoking novel about choices, regrets, and second chances, set in a magical library between life and death."
      ,
      long_description:"Set in the 1930s Deep South, To Kill a Mockingbird explores themes of racial inequality, moral integrity, and the innocence of childhood through the eyes of Scout Finch, a young girl growing up in Maycomb, Alabama. The novel follows Scout, her brother Jem, and their father, Atticus Finch, a lawyer tasked with defending Tom Robinson, a Black man falsely accused of raping a white woman. Through Scout’s narrative, the story delves into the complex social dynamics of a racially divided community, questioning the meaning of justice, compassion, and human decency. Harper Lee’s masterpiece remains a powerful commentary on the systemic racism that continues to echo through modern times."
  
    },
    {
      id: 6,
      image: 'https://th.bing.com/th/id/OIP.B_KN-E8TxiLabTyzaL4B9gHaK-?w=205&h=304&c=7&r=0&o=5&dpr=1.3&pid=1.7', // Replace with your image path
      categoryName: 'Tamil Novel',
      subCategoryName: 'Fiction',
      productName: 'Book one',
      price: 50,
      quantity: 1,
      SKU:"123A11",
      author:"krish", short_description:"A thought-provoking novel about choices, regrets, and second chances, set in a magical library between life and death."
      ,
      long_description:"Set in the 1930s Deep South, To Kill a Mockingbird explores themes of racial inequality, moral integrity, and the innocence of childhood through the eyes of Scout Finch, a young girl growing up in Maycomb, Alabama. The novel follows Scout, her brother Jem, and their father, Atticus Finch, a lawyer tasked with defending Tom Robinson, a Black man falsely accused of raping a white woman. Through Scout’s narrative, the story delves into the complex social dynamics of a racially divided community, questioning the meaning of justice, compassion, and human decency. Harper Lee’s masterpiece remains a powerful commentary on the systemic racism that continues to echo through modern times."
  
    },
    {
      id: 7,
      image: 'https://th.bing.com/th/id/OIP.WDp_38LGnYKjC8ad3PRIoAAAAA?w=207&h=300&c=7&r=0&o=5&dpr=1.3&pid=1.7',
      categoryName: 'Tamil Novel',
      subCategoryName: 'Fiction',
      productName: 'Book Two',
      price: 200,
      quantity: 1,
      SKU:"123A11",
      author:"krish", short_description:"A thought-provoking novel about choices, regrets, and second chances, set in a magical library between life and death."
      ,
      long_description:"Set in the 1930s Deep South, To Kill a Mockingbird explores themes of racial inequality, moral integrity, and the innocence of childhood through the eyes of Scout Finch, a young girl growing up in Maycomb, Alabama. The novel follows Scout, her brother Jem, and their father, Atticus Finch, a lawyer tasked with defending Tom Robinson, a Black man falsely accused of raping a white woman. Through Scout’s narrative, the story delves into the complex social dynamics of a racially divided community, questioning the meaning of justice, compassion, and human decency. Harper Lee’s masterpiece remains a powerful commentary on the systemic racism that continues to echo through modern times."
  
    },
    {
      id: 8,
      image: 'https://th.bing.com/th/id/OIP.OoAl690Iup8yqMEjOSHdWgHaJv?w=208&h=274&c=7&r=0&o=5&dpr=1.3&pid=1.7',
      categoryName: 'English Novel',
      subCategoryName: 'Fiction',
      productName: 'Book Three',
      price: 349,
      quantity: 1,
      SKU:"123A22",
      author:"Kannan", short_description:"A thought-provoking novel about choices, regrets, and second chances, set in a magical library between life and death."
      ,
      long_description:"Set in the 1930s Deep South, To Kill a Mockingbird explores themes of racial inequality, moral integrity, and the innocence of childhood through the eyes of Scout Finch, a young girl growing up in Maycomb, Alabama. The novel follows Scout, her brother Jem, and their father, Atticus Finch, a lawyer tasked with defending Tom Robinson, a Black man falsely accused of raping a white woman. Through Scout’s narrative, the story delves into the complex social dynamics of a racially divided community, questioning the meaning of justice, compassion, and human decency. Harper Lee’s masterpiece remains a powerful commentary on the systemic racism that continues to echo through modern times."
  
    },
    {
      id: 9,
      image: 'https://th.bing.com/th/id/OIP.yDePIbO4Lg1Imb8OspF-ggHaLM?w=203&h=307&c=7&r=0&o=5&dpr=1.3&pid=1.7',
      categoryName: 'English Novel',
      subCategoryName: 'Fiction',
      productName: 'Book Four',
      price: 500,
      quantity: 1,
      SKU:"123A33",
      author:"Kishor", short_description:"A thought-provoking novel about choices, regrets, and second chances, set in a magical library between life and death."
      ,
      long_description:"Set in the 1930s Deep South, To Kill a Mockingbird explores themes of racial inequality, moral integrity, and the innocence of childhood through the eyes of Scout Finch, a young girl growing up in Maycomb, Alabama. The novel follows Scout, her brother Jem, and their father, Atticus Finch, a lawyer tasked with defending Tom Robinson, a Black man falsely accused of raping a white woman. Through Scout’s narrative, the story delves into the complex social dynamics of a racially divided community, questioning the meaning of justice, compassion, and human decency. Harper Lee’s masterpiece remains a powerful commentary on the systemic racism that continues to echo through modern times."
  
    },
    {
      id: 10,
      image: 'https://th.bing.com/th/id/OIP.jE5aC4Yu98tsWhb8klAuNwHaLL?w=203&h=307&c=7&r=0&o=5&dpr=1.3&pid=1.7',
      categoryName: 'English novel',
      subCategoryName: 'Fiction',
      productName: 'Book five',
      price: 140,
      quantity: 1,
      SKU:"123A44",
      author:"Leo", short_description:"A thought-provoking novel about choices, regrets, and second chances, set in a magical library between life and death."
      ,
      long_description:"Set in the 1930s Deep South, To Kill a Mockingbird explores themes of racial inequality, moral integrity, and the innocence of childhood through the eyes of Scout Finch, a young girl growing up in Maycomb, Alabama. The novel follows Scout, her brother Jem, and their father, Atticus Finch, a lawyer tasked with defending Tom Robinson, a Black man falsely accused of raping a white woman. Through Scout’s narrative, the story delves into the complex social dynamics of a racially divided community, questioning the meaning of justice, compassion, and human decency. Harper Lee’s masterpiece remains a powerful commentary on the systemic racism that continues to echo through modern times."
  
    },
  ];

    export default product;


  

  