// src/components/Footer.js
import React, { useEffect, useState } from 'react';

const Footer = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleResize = () => {
    // Set footer visibility based on window size
    if (window.innerWidth <= 768) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    // Set initial visibility
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
    {isVisible && (
    <footer className="footer">
      <div className="container">
        <p>&copy; 2024 Narmadha Publications. All Rights Reserved.</p>
       
      </div>
    </footer>
    )}
    </>
  );
};

export default Footer;
