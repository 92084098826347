import React, { useEffect, useState, useContext } from 'react';
import { CartContext } from '../context/CartContext';
import { useParams } from 'react-router-dom';
import { doc, getDoc, query, collection, where, getDocs } from 'firebase/firestore';
import { db } from '../FireBase'; // Import your Firestore instance
import '../css/ProductPage.css'; 
import ProductSlider from '../components/ProductSlider';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import RelatedProductSlider from '../components/RelatedProductSlider';
import Loading from '../components/Loading';
import { useAlert } from '../context/AlertContext'; // Import useAlert
import { Icon } from '@mui/material';
import { FaShoppingBag } from 'react-icons/fa';

const ProductPage = () => {
  const { id } = useParams(); // Get the product id from the URL
  const { addToCart } = useContext(CartContext); // Access addToCart from context
  const { triggerAlert } = useAlert(); // Get the triggerAlert function from the alert context

  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]); // Initialize relatedProducts state
  const [categoryName, setCategoryName] = useState('');
  const [authorName, setAuthorName] = useState('');
  const [language] = useState(localStorage.getItem('language') || 'en'); // Default to English
 

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        if (id) {
          const productDoc = await getDoc(doc(db, 'products', id)); // Use id directly
          if (productDoc.exists()) {
            const productData = productDoc.data();
            console.log("Fetched Product Data:", productData); // Log fetched data
            setProduct(productData);
    
            // Fetch category name
            const categoryDoc = await getDoc(doc(db, 'categories', productData.category)); // Use categoryId
            if (categoryDoc.exists()) {
              if(language==='en'){
                setCategoryName(categoryDoc.data().nameEn);
              }else if(language==='ta'){
                setCategoryName(categoryDoc.data().nameTa);
              }
              
            }
    
            // Fetch author name
            const authorDoc = await getDoc(doc(db, 'authors', productData.author)); // Use authorId
            if (authorDoc.exists()) {
              if(language==='en'){
                setAuthorName(authorDoc.data().nameEn);
              }else if(language==='ta'){
                setAuthorName(authorDoc.data().nameTa);
              }
             
            }

            // Fetch related products
            fetchRelatedProducts(productData.category, productData.author);
          } else {
            console.error("Product not found");
          }
        } else {
          console.error("Product ID is undefined");
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [id]);

  const fetchRelatedProducts = async (categoryId, authorId, currentProductId) => {
    try {
      const related = [];
  
      // Fetch products by category (using categoryId)
      const categoryQuery = query(
        collection(db, 'products'),
        where('category', '==', categoryId)
      );
      const categorySnapshot = await getDocs(categoryQuery);
      categorySnapshot.forEach(async (doc) => {
        if (doc.id !== currentProductId) { // Exclude the current product
          const productData = doc.data();
          const categoryQuery = query(collection(db, 'categories'), where('id', '==', productData.category));
          const categorySnapshot = await getDocs(categoryQuery);
          if(language==='en')
            {
              const categoryName = categorySnapshot.docs[0].data().nameEn;
       
            }else if(language ==='ta')
              {
                const categoryName = categorySnapshot.docs[0].data().nameTa;
       
              }
           related.push({ id: doc.id, ...productData, categoryName });
        }
      });
  
      // Fetch products by author (using authorId), excluding duplicates from the category query
      const authorQuery = query(
        collection(db, 'products'),
        where('author', '==', authorId)
      );
      const authorSnapshot = await getDocs(authorQuery);
      authorSnapshot.forEach(async (doc) => {
        if (doc.id !== currentProductId && !related.some(prod => prod.id === doc.id)) {
          // Exclude the current product and avoid duplicates
          const productData = doc.data();
          const categoryQuery = query(collection(db, 'categories'), where('id', '==', productData.category));
          const categorySnapshot = await getDocs(categoryQuery);
          if(language==='en'){
            const categoryName = categorySnapshot.docs[0].data().nameEn;
          }else if(language==='ta'){
            const categoryName = categorySnapshot.docs[0].data().nameTa;
          }
         
          related.push({ id: doc.id, ...productData, categoryName });
        }
      });
  
      // Update the related products state
      setRelatedProducts(related);
      console.log("Related Products:", related); // Log related products
    } catch (error) {
      console.error("Error fetching related products:", error);
    }
  };

  if (!product) {
    return <div><Loading/></div>; // Show a loading state while fetching data
  }

  const isOutOfStock = product.stockStatus === 'OutOfStock';

  const handleAddToCart = () => {
    if (!isOutOfStock) {
      console.log()
      addToCart({
        customId: id, // must be the same as the product page 'id'
        name: product.productName,
        price: product.price,
        sku: product.sku,
        productImage: product.image,
      });
      triggerAlert('Book added to your bag!', 'success'); // Trigger success alert
    }
  };

  return (
    <Container className="product-page py-5">
      <Row className="justify-content-center">
        {/* Product Details Section */}
        <Col xs={12} md={6} className="text-center">
          <Image 
            src={product.productImage} // Make sure your field name is correct
            alt={product.productName} 
            fluid 
            className="product-image mb-4"
          />
        </Col>
        
        <Col xs={12} md={6}>
          <h1> {language === 'en' ? product.nameEn : product.nameTa}</h1>
          <p className="text-muted">{categoryName}</p>
          <p className="text-muted">Author: {authorName}</p>
          <p className="text-muted">SKU: {product.sku}</p>
          
          <h3 className="text">Price: ₹{product.price}</h3>

          {/* Out of Stock Message */}
          {isOutOfStock && (
            <p className="text-danger">This product is currently out of stock.</p>
          )}
          
          <Button 
            onClick={handleAddToCart} // Call the new handler
            variant="success" 
            className="add-to-cart-p mt-3"
            size="md"
            disabled={isOutOfStock}
            style={{
              width: '100%',
              borderRadius: '20px',
              backgroundColor: isOutOfStock ? 'grey' : '#007bff',
              cursor: isOutOfStock ? 'not-allowed' : 'pointer'
            }}
          >
          {isOutOfStock ? 'Out of Stock' : (
    <>
      <FaShoppingBag size={20} style={{ marginRight: '8px',marginBottom:'6px'}} /> {/* Add Icon */}
      Add to Bag
    </>
  )}

          </Button>

          <br /><br />
          <div className='container-des'>
            <p className="text-muted" style={{textAlign: 'justify', padding: '10px'}}>
              <b>Description</b><br /> {language === 'en' ? product.descriptionEn : product.descriptionTa}
            </p>
          </div>
        </Col>
      </Row>

      {/* Related Products Section */}
      <Row className="related-products mt-5 container-cus">
        <Col xs={12}>
          <h2 className="text-center">Related Products</h2>
          <br />
          <RelatedProductSlider products={relatedProducts} /> 
        </Col>
      </Row>
    </Container>
  );
};

export default ProductPage;
