// ProductCard.jsx
import React from 'react';
import '../css/SearchResult.css'; // Import the CSS file for styling
import { Link } from 'react-router-dom';
import products from '../data/Product';


const SearchResult = ({ id, image, categoryName, productName, price,author,SKU },{addToCart}) => { // Properly destructure addToCart
  const product = products.find((prod) => prod.id === parseInt(id));
  return (
    <>
      <div className="container listcon  shadow-lg">
      <Link to={`/product/${id}`} >
        <div className="row ">
            <div className="col-md-2 col-5 d-flex justify-content-center align-items-centerd-flex justify-content-center align-items-center ">
                <center>
             <img src={image} alt={productName} className="search-product-image " />
             </center>
            </div>
            <div className="col-md-10 col-7 text-d cu-link"  >
              <p className="product-category ">{categoryName} - SKU:{SKU} - By:{author}</p>
              <p className="product-category"></p>
              <h3 className="product-name">{productName}</h3>
              <p className="product-category"></p>
              <p className="product-category">₹{price}</p>
            </div>
           
        </div>
        </Link>

      </div>
      <br></br>
    </>
  );
};

export default SearchResult;
