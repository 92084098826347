import React, { useState, useEffect } from 'react';
import { collection, getDocs, where, query } from 'firebase/firestore';
import { db } from '../FireBase'; // Your Firebase configuration
import ProductSlider from './ProductSlider'; // Your ProductSlider component
import LoadingComponent from '../components/Loading'; // Import your loading component

const DynamicProductSlider = () => {
  const [activeTags, setActiveTags] = useState([]); // To store active tag IDs
  const [tagsMap, setTagsMap] = useState({}); // To store tag ID to tag name mapping
  const [products, setProducts] = useState([]); // To store products with active tags
  const [loading, setLoading] = useState(true); // Loading state
  const [language] = useState(localStorage.getItem('language') || 'en'); // Default to English
 
 

  // Fetch active tags from Firestore
  useEffect(() => {
    const fetchActiveTags = async () => {
      setLoading(true); // Start loading
      const activeTagsCollection = await getDocs(collection(db, 'product_slider_settings'));
      const activeTagIds = activeTagsCollection.docs
        .filter(doc => doc.data().status === 'active')
        .sort((a, b) => a.data().queueNumber - b.data().queueNumber)
        .map(doc => doc.id);
      setActiveTags(activeTagIds);
      setLoading(false); // End loading
    };

    fetchActiveTags();
  }, []);

  // Fetch tags from Firestore
  useEffect(() => {
    const fetchTags = async () => {
      setLoading(true); // Start loading
      const tagsCollection = await getDocs(collection(db, 'tags'));
      const tagsData = {};
      tagsCollection.docs.forEach(doc => {
        const tagId = doc.id; // Document ID is the tag ID
        const tagName = doc.data().name; // Assuming tag has a 'name' field
        tagsData[tagId] = tagName; // Map tag ID to tag name
      });
      setTagsMap(tagsData); // Store the tag map
      setLoading(false); // End loading
    };

    fetchTags();
  }, []);

  // Fetch products with active tags from Firestore
  useEffect(() => {
    const fetchProducts = async () => {
      if (activeTags.length === 0) return; // Exit if no active tags

      setLoading(true); // Start loading
      let allFilteredProducts = [];

      // Execute separate queries for each active tag
      for (const tagId of activeTags) {
        const productsCollection = collection(db, 'products');
        const q = query(productsCollection, where('tags', 'array-contains', tagId));
        const productsSnapshot = await getDocs(q);
        const filteredProducts = productsSnapshot.docs.map(async (doc) => {
          const productData = doc.data();
          const categoryId = productData.category;
          const categoryQuery = query(collection(db, 'categories'), where('id', '==', categoryId));
          const categorySnapshot = await getDocs(categoryQuery);
          let categoryName = null; // Change from const to let
          if (language === 'en') {
            categoryName = categorySnapshot.docs[0].data().nameEn;
          } else if (language === 'ta') { // Make sure the language code is correct
            categoryName = categorySnapshot.docs[0].data().nameTa;
          }
          console.log(categoryName);
          
          return {
            id: doc.id,
            ...productData,
            categoryName,
          };
        });

        // Merge results
        const resolvedProducts = await Promise.all(filteredProducts);
        allFilteredProducts = [...allFilteredProducts, ...resolvedProducts];
      }

      // Remove duplicates based on product ID
      const uniqueProducts = Array.from(new Map(allFilteredProducts.map(product => [product.id, product])).values());

      setProducts(uniqueProducts);
      setLoading(false); // End loading
    };

    fetchProducts();
  }, [activeTags]); // Run this effect whenever activeTags changes

  return (
    <div className='padding-con'>
      <br />
      {loading ? (
        <LoadingComponent /> // Render loading component if loading is true
      ) : (
        activeTags.map((tagId, index) => (
          <div key={index}>
            <h2>{tagsMap[tagId]}</h2> {/* Displaying tag name */}
            <hr />
            <ProductSlider products={products.filter(product => product.tags.includes(tagId))} />
          </div>
        ))
      )}
    </div>
  );
};

export default DynamicProductSlider;
