import React, { createContext, useState, useEffect,useContext } from 'react';

// Create the context

export const useCart = () => {
    return useContext(CartContext);
  };

export const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  // Load cart items from localStorage on component mount
  useEffect(() => {
    const storedCart = localStorage.getItem('cartItems');
    if (storedCart) {
      setCartItems(JSON.parse(storedCart)); // Parse and set cart if it exists
    }
  }, []);

  // Save updated cart to localStorage when cartItems change
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems)); // Update localStorage
  }, [cartItems]);

  // Add to cart function
  const addToCart = (product) => {
    const existingProduct = cartItems.find((item) => item.customId === product.customId);

    if (existingProduct) {
      // Update quantity of existing product
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.customId === product.customId ? { ...item, quantity: item.quantity + 1 } : item
        )
      );
    } else {
      // Add new product to cart
      setCartItems([...cartItems, { ...product, quantity: 1 }]);
    }

    // Alert message setup
    setAlertMessage('Product added successfully!');
    setShowAlert(true);

    // Automatically hide the alert after 3 seconds
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  // Function to update product quantity
  const updateQuantity = (itemId, newQuantity) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.customId === itemId ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  const clearCart = () => {
    setCartItems([]); // Update the state to clear the cart
    localStorage.removeItem('cartItems'); // Clear from local storage
  };

  // Function to remove product from cart
  const removeFromCart = (productId) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.customId !== productId));
  };
  const cartItemCount = cartItems.reduce((total, item) => total + item.quantity, 0);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        updateQuantity,
        alertMessage,
        showAlert,
        cartItemCount,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
