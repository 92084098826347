// src/components/Loading.js
import React from 'react';
import { Spinner } from 'react-bootstrap'; // Import Spinner from React Bootstrap

const Loading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <Spinner animation="grow" role="status" style={{color:'#023e6f'}}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default Loading;
