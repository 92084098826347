import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../FireBase';
import { CartContext } from '../context/CartContext'; // Import the CartContext
import EmptyCart from './EmptyCart';
import '../css/Cart.css';

const Cart = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext); // Use the cart context

  const [minCartValue, setMinCartValue] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [minCartEnabled, setMinCartEnabled] = useState(false);

  const totalCartValue = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
  const [language] = useState(localStorage.getItem('language') || 'en'); // Default to English
  

  // Fetch shipping charges settings from Firestore
  useEffect(() => {
    const fetchShippingCharges = async () => {
      try {
        const docRef = doc(db, 'shipping_charges', 'default');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setMinCartValue(data.minCartValue);
          setShippingCharge(data.shippingCharge);
          setMinCartEnabled(data.minCartEnabled);
        } else {
          console.error('No shipping charge settings found!');
        }
      } catch (error) {
        console.error('Error fetching shipping charges:', error);
      }
    };

    fetchShippingCharges();
  }, []);

  const calculateShippingCharge = () => {
    if (minCartEnabled) {
      if (totalCartValue < minCartValue) {
        return shippingCharge;
      } else {
        return 0;
      }
    } else {
      return shippingCharge;
    }
  };

  const calculatedShippingCharge = calculateShippingCharge();
  const grandTotal = totalCartValue + calculatedShippingCharge;

  const handleCheckout = () => {
    const user = auth.currentUser;
    if (user) {
      navigate('/checkout', {
        state: {
          cartItems,
          totalCartValue,
          shippingCharge: calculatedShippingCharge,
          grandTotal,
        },
      });
    } else {
      navigate('/account');
    }
  };

  return (
    <div className="cart-container container py-4" style={{ minHeight: '90vh' }}>
      <center>
        <h2>Your Bag</h2>
        <hr />
      </center>

      {cartItems.length === 0 ? (
        <EmptyCart />
      ) : (
        <Row className='padding-con'>
          <Col md={8} className="cus-con">
            <Table responsive >
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item) => (
                  <tr key={item.customId}>
                    <td>
                      <Row>
                        <Col xs={4} sm={3}>
                          <img
                            src={item.productImage}
                            alt={item.name}
                            className="img-fluid cart-item-image"
                            
                          />
                        </Col>
                        <Col xs={8} sm={9}>
                          <p className="product-name">
                         {item.name}-{item.sku}
                          </p>
                        </Col>
                      </Row>
                    </td>
                    <td>₹{item.price}</td>
                    <td>
                      <div className="quantity-control d-flex align-items-center">
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          onClick={() => updateQuantity(item.customId, item.quantity - 1)}
                          disabled={item.quantity === 1}
                        >
                          -
                        </Button>
                        <span className="mx-2">{item.quantity}</span>
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          onClick={() => updateQuantity(item.customId, item.quantity + 1)}
                        >
                          +
                        </Button>
                      </div>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        className="mt-2"
                        onClick={() => removeFromCart(item.customId)}
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col md={4}>
            <div className="cart-summary p-3 rounded">
              <h5 className="text-center">Order Summary</h5>
              <hr />
              <p>Subtotal: ₹{totalCartValue}</p>
              <p>Shipping: ₹{calculatedShippingCharge}</p>
              <p>Total: ₹{grandTotal}</p>
              <Button variant="success" className="w-100" onClick={handleCheckout}>
                Proceed to Checkout
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Cart;
