import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { collection, getDocs } from 'firebase/firestore';
import '../css/Carousel.css';
import { db } from '../FireBase'; // Importing 'db' directly

const CarouselComponent = () => {
  const [sliderImages, setSliderImages] = useState([]);

  useEffect(() => {
    const fetchSliderImages = async () => {
      const querySnapshot = await getDocs(collection(db, 'slider_images'));
      const images = querySnapshot.docs.map(doc => doc.data());
      setSliderImages(images);
      console.log(images);
    };
    
    fetchSliderImages();
  }, []);
 

  return (
    <div className='car-container'>
      <Carousel controls={false} indicators={false}>
        {sliderImages.map((image, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100 h-50 cus-size"
              src={image.imageUrl} // Assuming your Firestore documents have a 'url' field
              alt={`Slide ${index + 1}`}
            />
            {/* <Carousel.Caption>
              <h3>{`Slide ${index + 1}`}</h3>
              <p>{image.description || `This is the description for slide ${index + 1}.`}</p>
            </Carousel.Caption> */}
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselComponent;
