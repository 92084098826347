import React, { useState, useEffect } from 'react';
import '../css/Search.css'; // Import your custom CSS file
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import SearchResult from './SearchResult';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../FireBase'; // Firestore instance
import Loading from './Loading';
import NoResultsFound from './NoResultFound';

const SearchComponent = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filterBy, setFilterBy] = useState('name'); // Default filter by name
  const [products, setProducts] = useState([]); // Initialize as an empty array
  const [filteredProducts, setFilteredProducts] = useState([]); // Initialize as an empty array
  const [categories, setCategories] = useState({});
  const [authors, setAuthors] = useState({});
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true); // Show loading screen
      // Fetch products
      const productSnapshot = await getDocs(collection(db, 'products'));
      const productList = productSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Fetch category names
      const categorySnapshot = await getDocs(collection(db, 'categories'));
      const categoryData = {};
      categorySnapshot.forEach((doc) => {
        categoryData[doc.id] = doc.data().name; // Assuming 'name' is the field with category name
      });
      setCategories(categoryData);

      // Fetch author names
      const authorSnapshot = await getDocs(collection(db, 'authors'));
      const authorData = {};
      authorSnapshot.forEach((doc) => {
        authorData[doc.id] = doc.data().name; // Assuming 'name' is the field with author name
      });
      setAuthors(authorData);

      // Combine product list with category and author names
      const combinedProducts = productList.map((product) => ({
        ...product,
        categoryName: categoryData[product.category] || 'Unknown Category',
        authorName: authorData[product.author] || 'Unknown Author',
      }));

      setProducts(combinedProducts);
      setLoading(false); // Hide loading screen
    };

    fetchProducts();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    let results = [];

    switch (filterBy) {
      case 'name':
        results = products.filter((product) =>
          product.name?.toLowerCase().includes(query) // Safe check for undefined name
        );
        break;
      case 'category':
        results = products.filter((product) =>
          product.categoryName?.toLowerCase().includes(query) // Safe check for undefined category
        );
        break;
      case 'sku':
        results = products.filter((product) =>
          product.sku?.toLowerCase().includes(query) // Safe check for undefined SKU
        );
        break;
      case 'author':
        results = products.filter((product) =>
          product.authorName?.toLowerCase().includes(query) // Safe check for undefined author
        );
        break;
      default:
        results = products;
    }

    setFilteredProducts(results);
  };

  return (
    <>
      <Container style={{minHeight:'100vh'}}>
        <Row className="justify-content-center cus">
          <Col xs={12} md={8}>
            <center>
              <h1>Search</h1>
            </center>

            <div className="my-content">
              {loading ? (
                <div className="loading-screen">
                 <Loading/>
                </div>
              ) : (
                <>
                  <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={filterBy}
                      onChange={(e) => setFilterBy(e.target.value)}
                      style={{ marginRight: '10px', width: 'auto' }}
                    >
                      <option value="name">Name</option>
                      <option value="category">Category</option>
                      <option value="sku">SKU</option>
                      <option value="author">Author</option>
                    </select>

                    <input
                      className="form-control cus-input"
                      type="text"
                      placeholder={`Search by ${filterBy}`}
                      value={searchQuery}
                      onChange={handleSearch}
                      style={{ width: '100%' }}
                    />
                  </div>

                  <div>
                    <hr />
                    <h3>Search Results:</h3>
                    {searchQuery ? (
                      filteredProducts.length > 0 ? (
                        filteredProducts.map((product) => (
                          <div key={product.id}>
                            <SearchResult
                              id={product.id}
                              image={product.productImage}
                              categoryName={product.categoryName}
                              productName={product.name}
                              SKU={product.sku}
                              author={product.authorName}
                              price={product.price}
                            />
                          </div>
                        ))
                      ) : (
                        <NoResultsFound/>
                      )
                    ) : (
                      <p>Please enter a search query.</p>
                    )}
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SearchComponent;
