// src/pages/HomePage.js
import React, { useState, useEffect } from 'react';
import ProductSlider from '../components/ProductSlider';

import Carousel from '../components/Carousel';
import CategoryList from '../components/CategoryList';
import './HomePage.css'; // Import the CSS file for styling
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../FireBase'; // Firestore instance
import Loading from '../components/Loading';
import DynamicProductSlider from '../components/DynamicProductSlider';
import { MdCategory } from 'react-icons/md'; // Import the category icon


const HomePage = () => {
  const [isCategoryVisible, setIsCategoryVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState({});
  const [authors, setAuthors] = useState({});
  const [loading, setLoading] = useState(true); // Loading state

  const toggleCategoryVisibility = () => {
    setIsCategoryVisible(!isCategoryVisible);
  };
  const [language] = useState(localStorage.getItem('language') || 'en'); // Default to English
 
 
    console.log(language)
  
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // Fetch products
        const productSnapshot = await getDocs(collection(db, 'products'));
        const productList = productSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch category names
        const categorySnapshot = await getDocs(collection(db, 'categories'));
        const categoryData = {};
        categorySnapshot.forEach((doc) => {
          if(language==='en'){
            categoryData[doc.id] = doc.data().nameEn;
          }else if(language==='ta'){
            categoryData[doc.id] = doc.data().nameTa; // Assuming 'name' is the field with category name

          }
               });
        setCategories(categoryData);
        console.log(categoryData)

        // Fetch author names
        const authorSnapshot = await getDocs(collection(db, 'authors'));
        const authorData = {};
        authorSnapshot.forEach((doc) => {
          if(language==='en'){
            authorData[doc.id] = doc.data().nameEn;
          }else if(language==='ta'){
            authorData[doc.id] = doc.data().nameTa;
          }
          
          // Assuming 'name' is the field with author name
        });
        setAuthors(authorData);
        console.log(authorData)

        // Combine product list with category and author names
        const combinedProducts = productList.map((product) => ({
          ...product,
          categoryName: categoryData[product.category] || 'Unknown Category',
          authorName: authorData[product.author] || 'Unknown Author',
        }));

        setProducts(combinedProducts);
        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false even on error
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    // Render loading screen
    return (
      <div className="loading-screen">
      <Loading/>
      </div>
    );
  }

  return (
    <>
      <Carousel />
      <div className="container">
        <div className="row">
          {/* Toggle button for mobile view */}
          <div className={`col-12 d-md-none ${isCategoryVisible ? 'sticky-toggle' : ''}`}>
          <button
      className="btn sticky-btn"
      style={{ bottom: '8%', backgroundColor: 'green', color: 'white', border: 'none',fontSize:'16px' }}
      onClick={toggleCategoryVisibility}
    >
      <MdCategory size={25}style={{marginRight:'5px'}} /> {/* Add the category icon with some margin */}
      {isCategoryVisible ? 'Hide Categories' : 'Categories'}
    </button>
          </div>

          {/* Category Column */}
          <div className={`filter-sticky col-md-3 category-column ${isCategoryVisible ? 'visible' : 'd-none d-md-block'}`}>
            <br />
            <h3>Categories</h3>
            <CategoryList />
          </div>

          <div className="col-md-9">
            <DynamicProductSlider/>
          </div>

          {/* Main Content Column */}
          {/* <div className="col-md-9">
            <br />
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-6 col-6 text-start">
                <h2>Best Sellers</h2>
                <br />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6 col-6 text-end">
                <button className="btn-own">Explore more</button>
              </div>
            </div>

            <div className="product-container">
              <ProductSlider products={products} />
            </div>

            <br />
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-6 col-6 text-start">
                <h2>New Arrivals</h2>
                <br />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6 col-6 text-end">
                <button className="btn-own">Explore more</button>
              </div>
            </div>
            <ProductSlider products={products} />
          
          </div> */}
        </div>
        <br />
      </div>
    </>
  );
};

export default HomePage;
