// src/components/AuthForm.js
import React, { useState, useContext } from 'react';
import { Form, Button, Row, Col, Alert, Container } from 'react-bootstrap';
import '../css/AuthForm.css'; // Import your custom CSS file
import { AuthContext } from '../context/AuthContext'; // Import AuthContext
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { db } from '../FireBase'; // Import your Firestore instance
import { collection, addDoc } from 'firebase/firestore'; // Firestore functions

const AuthForm = () => {
  const { login, register,alert } = useContext(AuthContext); // Access login and register functions from AuthContext
  const navigate = useNavigate(); // Initialize useNavigate
  const [formType, setFormType] = useState('login'); // State to toggle between login and register
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    name: '', // Added name field for registration
    phone: '', // Added phone field for registration
  });
  const [error, setError] = useState(null); // To display error messages
  const [success, setSuccess] = useState(null); // To display success messages
  const [loading, setLoading] = useState(false); // To indicate loading state

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    const { email, password, confirmPassword, name, phone } = formData;

    // Basic validation
    if (formType === 'register') {
      if (password !== confirmPassword) {
        setError('Passwords do not match.');
        return;
      }
      if (!name.trim()) {
        setError('Name is required for registration.');
        return;
      }
      if (!phone.trim()) {
        setError('Phone number is required for registration.');
        return;
      }
    }

    setLoading(true); // Start loading

    try {
      if (formType === 'login') {
        // Perform login using Firebase
        await login({ email, password });
        
        // Redirect to account details page after a short delay
        setTimeout(() => {
          
          navigate('/account'); // Adjust the path as needed
        }, 1500);
      } else {
        // Perform registration using Firebase
        const userCredential = await register({ email, password,name,phone }); // Get userCredential from the register function
        const user = userCredential.user;
        // Redirect to account details page after a short delay
        setTimeout(() => {
          navigate('/account'); // Adjust the path as needed
        }, 1500);
      }
    } catch (err) {
      setError(err.message || 'An error occurred.');
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <div className="auth-bg">
      <Container className="justify-content-center align-items-center auth-container">
        <Row className="justify-content-center">
          <Col md={12} lg={12}>
            <div className="text-center mb-4">
              <h2>{formType === 'login' ? 'Login' : 'Register'}</h2>
              {alert && (
              <Alert variant="danger">
                {alert} {/* Display error message from AuthContext */}
              </Alert>)}
              <Button
                variant="link"
                style={{ textDecoration: 'none' }}
                onClick={() => {
                  setFormType(formType === 'login' ? 'register' : 'login');
                  setError(null);
                  setSuccess(null);
                  setFormData({
                    email: '',
                    password: '',
                    confirmPassword: '',
                    name: '',
                    phone: '', // Reset phone number field
                  }); // Reset form data when toggling
                }}
              >
                {formType === 'login'
                  ? 'Create an account'
                  : 'Already have an account? Login'}
              </Button>
            </div>

            {/* Display error or success messages */}
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}

            <Form onSubmit={handleSubmit}>
              {formType === 'register' && (
                <>

                  <Form.Group controlId="formName" className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Enter your name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                      style={{ width: '100%' }}
                    />
                  </Form.Group>
                  
                  <Form.Group controlId="formPhone" className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      placeholder="Enter your phone number"
                      value={formData.phone}
                      onChange={handleInputChange}
                      required
                      style={{ width: '100%' }}
                    />
                  </Form.Group>
                </>
              )}

              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  style={{ width: '100%' }}
                />
              </Form.Group>

              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                  style={{ width: '100%' }}
                />
              </Form.Group>

              {formType === 'register' && (
                <Form.Group controlId="formConfirmPassword" className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    required
                    style={{ width: '100%' }}
                  />
                </Form.Group>
              )}

              <Button
                variant="primary"
                type="submit"
                className="w-100 mt-4"
                disabled={loading}
              >
                {loading
                  ? formType === 'login'
                    ? 'Logging in...'
                    : 'Registering...'
                  : formType === 'login'
                  ? 'Login'
                  : 'Register'}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AuthForm;
