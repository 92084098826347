import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../FireBase'; // Firestore instance
import ProductCard from '../components/ProductCard';
import { useParams } from 'react-router-dom';
import './ShopPage.css'; // Add your CSS for mobile and sticky filter
import Loading from '../components/Loading';
import { BsFunnel } from 'react-icons/bs';


const ShopPage = (addToCart) => {
  const { categoryId } = useParams(); // Get categoryId from the URL
  console.log(categoryId);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState({});
  const [authors, setAuthors] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedAuthor, setSelectedAuthor] = useState('');
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [language] = useState(localStorage.getItem('language') || 'en'); // Default to English
 
 


  useEffect(() => {
    const fetchProducts = async () => {
      // Fetch products
      setLoading(true); // Set loading to true before fetching
      try {
      const productSnapshot = await getDocs(collection(db, 'products'));
      const productList = productSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Fetch category names
      const categoryIds = [...new Set(productList.map(product => product.categoryId))];
      const categorySnapshot = await getDocs(collection(db, 'categories'));
      const categoryData = {};
      categorySnapshot.forEach(doc => {
        if(language==='en')
          {
            categoryData[doc.id] = doc.data().nameEn; // Assuming 'name' is the field with category name
          }else if(language==='ta'){
            categoryData[doc.id] = doc.data().nameTa; // Assuming 'name' is the field with category name
          }
       
      });
      setCategories(categoryData);

      // Fetch author names
      const authorIds = [...new Set(productList.map(product => product.authorId))];
      const authorSnapshot = await getDocs(collection(db, 'authors'));
      const authorData = {};
      authorSnapshot.forEach(doc => {
        if(language==='en')
          {
            authorData[doc.id] = doc.data().nameEn; // Assuming 'name' is the field with author name
          }else if(language==='ta'){
            authorData[doc.id] = doc.data().nameTa; // Assuming 'name' is
          }
      });
      setAuthors(authorData);
      console.log(authorData);

      // Combine product list with category and author names
      const combinedProducts = productList.map(product => ({
        ...product,
        categoryName: categoryData[product.category] || 'Unknown Category',
        authorName: authorData[product.author] || 'Unknown Author',
      }));

      setProducts(combinedProducts);
    
    }catch{
      console.error("Error fetching products: ");
    }finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    setSelectedCategory(categoryId || '');
  }, [categoryId]);
  
  // Filter logic
  const filteredProducts = products.filter(product => {
    const matchesCategory = selectedCategory === '' || product.categoryName === selectedCategory ||  product.category === selectedCategory;

    console.log("come");
    const matchesAuthor = selectedAuthor === '' || product.authorName === selectedAuthor;
    const matchesPrice = product.price >= priceRange[0] && product.price <= priceRange[1];
    return matchesCategory && matchesAuthor && matchesPrice;
  });

  // Function to handle filter application
  const applyFilter = () => {
    setAlertVisible(true);
    if (isFilterVisible) {
      setIsFilterVisible(false);
    }
    setTimeout(() => {
      setAlertVisible(false);
    }, 2000);
  };

  // Function to remove filters
  const removeFilters = () => {
    setSelectedCategory('');
    setSelectedAuthor('');
    setPriceRange([0, 1000]);
  };

  const isFilterActive = selectedCategory !== '' || selectedAuthor !== '' || priceRange[0] !== 0 || priceRange[1] !== 1000;
  const handleAddToCart = async (product) => {
    // Add your add to cart logic here
    console.log(product);
    addToCart(product);
    
  };
  return (
    <Container>
      <br />
      <h1>Shop</h1>
      <hr />

      {alertVisible && <Alert variant="success">Filter applied successfully!</Alert>}
      {loading && (
        <div className="text-center">
          <Loading/>
        </div>
      )}
 {!loading && (
      <Row>
        <Col md={3} className={`filter-column ${isFilterVisible ? 'show-filter' : ''}`}>
          <div className="filter-sticky">
            <h4>Filter</h4>

            {/* Category Filter */}
            <Form.Group className="mb-3">
              <Form.Label>Category</Form.Label>
              <div className="category-list" style={{  }}>
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                  {Object.entries(categories).map(([id, name]) => (
                    <li key={id} style={{ marginBottom: '8px' }}>
                      <Button
                        className='filter-btn'
                        variant={selectedCategory === name ? 'primary' : 'outline-primary'}
                        onClick={() => setSelectedCategory(name)}
                      >
                       {name}
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            </Form.Group>

            {/* Author Filter */}
            <Form.Group className="mb-3">
              <Form.Label>Author</Form.Label>
              <Form.Control as="select" value={selectedAuthor} onChange={(e) => setSelectedAuthor(e.target.value)}>
                <option value="">All Authors</option>
                {Object.entries(authors).map(([id, name]) => (
                  <option key={id} value={name} style={{width:'100%'}}>
                    {name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Price Filter */}
            <Form.Group className="mb-3">
              <Form.Label>Price Range</Form.Label>
              <Form.Range
                value={priceRange[1]}
                onChange={(e) => setPriceRange([0, Number(e.target.value)])}
                min={0}
                max={1000}
              />
              <span>Price: ₹{priceRange[0]} - ₹{priceRange[1]}</span>
            </Form.Group>

            <button className="btn btn-warning" onClick={applyFilter}>
              Apply Filter
            </button>

            {isFilterActive && (
              <Button className="btn btn-danger" variant="secondary" onClick={removeFilters}>
                Remove Filters
              </Button>
            )}
            <br/>  <br/>
          </div>
        </Col>

        <Col md={9}>
          <Button className="d-block d-md-none mb-3 sticky-btn" style={{ bottom: '7%', backgroundColor: 'green', color: 'white', border: 'none' }} onClick={() => setIsFilterVisible(!isFilterVisible)}>
          <BsFunnel size={25}style={{marginRight:'5px'}}/>{isFilterVisible ? 'Hide Filter' : 'Filter'}
          </Button>

          <h2>Products</h2>
          <Row className='padding-con'>
  {filteredProducts.length > 0 ? (
    filteredProducts.map((product) => {
      // Determine language from local storage
      const language = localStorage.getItem('language') || 'en'; // Default to English

      // Set product name and category based on language
      const productName = language === 'en' ? product.nameEn : product.nameTa;
    

      return (
        <Col xl={3} lg={4} md={6} sm={6} xs={6} key={product.id}>
          <ProductCard 
            id={product.id}
            image={product.productImage}
            categoryName={product.categoryName} // Use the dynamic category name
            productName={productName} // Use the dynamic product name
            price={product.price}
            stock={product.stockStatus} 
            addToCart={addToCart}
            sku={product.sku}
          />
        </Col>
      );
    })
  ) : (
    <h6>No products match the filters.</h6>
  )}
</Row>

        </Col>
      </Row>
    )}

    </Container>
  );
};

export default ShopPage;
