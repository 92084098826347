// src/components/OrderHistory.js
import React, { useContext, useEffect, useState } from 'react';
import { db } from '../FireBase'; // Import Firestore
import { collection, getDocs, query, where,orderBy } from 'firebase/firestore';
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { Card, Row, Col } from 'react-bootstrap'; // Import Card, Row, and Col from React Bootstrap
import Loading from './Loading';

const OrderHistory = () => {
  const { currentUser } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      if (currentUser) {
        console.log("Current User ID:", currentUser.uid); // Check user ID
        try {
          const ordersCollection = collection(db, 'orders');
          const q = query(
            ordersCollection, 
            where('userId', '==', currentUser.uid),
            orderBy('createdAt', 'desc') 
          );
          const orderSnapshot = await getDocs(q);
          console.log("Order Snapshot:", orderSnapshot); // Check snapshot
  
          const orderList = orderSnapshot.docs.map(doc => ({
            id: doc.data().orderId,
            date: doc.data().createdAt.toDate().toLocaleDateString(),
            total: doc.data().totalValue,
            status: doc.data().status,
          }));
  
          console.log("Orders List:", orderList); // Check parsed orders
          setOrders(orderList);
        } catch (error) {
          console.error("Error fetching orders: ", error);
        }
      } else {
        console.log("No current user");
      }
      setLoading(false);
    };
  
    fetchOrders();
  }, [currentUser]);
  

  if (loading) return <Loading />; // Show loading component

  return (
    <>
     
      <Row xs={1} md={2} lg={3} className="g-4 padding-con">
        {orders.length > 0 ? (
          orders.map((order) => (
            <Col key={order.id}>
              <Card className="shadow">
                <Card.Body>
                  <Card.Title>Order ID: 
                    <Link to={`/order/${order.id}`} className="text-decoration-none">
                       {order.id}
                    </Link>
                  </Card.Title>
                  <Card.Text>
                    <strong>Date:</strong> {order.date}<br />
                    <strong>Total:</strong> ₹{parseFloat(order.total).toFixed(2)}<br />
                    <strong>Status:</strong>
                    <strong><span
                      style={{
                        color: order.status === 'Processing' ? 'orange' :order.status === 'Packed' ? 'red' :order.status === 'Shipped' ? 'blue' : order.status === 'Delivered' ? 'green' : 'black',
                      }}
                    >
                                    {order.status}
                    </span></strong>

                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <Col>
            <Card className="text-center">
              <Card.Body>
                <Card.Text>No orders found</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default OrderHistory;
