// src/components/OrderDetails.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../FireBase'; // Import Firestore
import { doc, getDoc,query,collection,where ,getDocs} from 'firebase/firestore';
import Loading from '../components/Loading';

const OrderDetails = () => {
  const { orderId } = useParams(); // Get order ID from URL
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
   const fetchOrderDetails = async () => {
   
  try {
    const q = query(collection(db, 'orders'), where('orderId', '==', orderId)); // Query by orderId field
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      // Fetch the first matching document (assuming orderId is unique)
      const doc = querySnapshot.docs[0]; 
      setOrderDetails(doc.data()); // Set the order details
    } else {
      console.error('No such order found!');
    }
  } catch (error) {
    console.error('Error fetching order details: ', error);
  }finally{
    setLoading(false);
  }
};
    fetchOrderDetails();
  }, [orderId]);

  if (loading) return <Loading/>;

  return (
    <div className="container padding-con">
        <br/>
        <div className="che-con ">
      <h2>Order Details</h2>
      {orderDetails ? (
        <>
          <h5>Order ID: {orderDetails.orderId}</h5>
          <h5>Status: <strong><span
                      style={{
                        color: orderDetails.status === 'Processing' ? 'orange' : orderDetails.status === 'Completed' ? 'green' : 'black',
                      }}
                    >
                                    {orderDetails.status}
                    </span></strong></h5>
          <h5>Order Summary:</h5>
          <table className="table table-striped">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Price (₹)</th>
                  <th>Total (₹)</th>
                </tr>
              </thead>
              <tbody>
                {orderDetails.items.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.quantity}</td>
                    <td>{item.price}</td>
                    <td>{(item.quantity * item.price).toFixed(2)}</td> {/* Calculate total for each item */}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="3" className="text-right"><strong>Total Value:</strong></td>
                  <td><strong>₹{orderDetails.totalValue.toFixed(2)}</strong></td>
                </tr>
                <tr>
                  <td colSpan="3" className="text-right"><strong>Shipping Charges:</strong></td>
                  <td><strong>₹{orderDetails.shippingCharge.toFixed(2)}</strong></td>
                </tr>
                <tr>
                  <td colSpan="3" className="text-right"><strong>Grand Total:</strong></td>
                  <td><strong>₹{orderDetails.grandTotal.toFixed(2)}</strong></td>
                </tr>
              </tfoot>
            </table>
          <h5>Shipping Address:</h5>
          <p>{orderDetails.shippingAddress.fullName}</p>
          <p>{orderDetails.shippingAddress.addressLine1}</p>
          <p>{orderDetails.shippingAddress.addressLine2}</p>
          <p>{orderDetails.shippingAddress.city}, {orderDetails.shippingAddress.state}, {orderDetails.shippingAddress.postalCode}, {orderDetails.shippingAddress.country}</p>
          <p>Phone: {orderDetails.shippingAddress.phone}</p>
        </>
      ) : (
        <p>No order details available.</p>
      )}
      </div>
      <br/>
    </div>
    

  );
};

export default OrderDetails;
