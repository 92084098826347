import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ShopPage from './pages/ShopPage';
import AccountPage from './pages/Account';
import SearchPage from './pages/SearchPage';
import ProductPage from './pages/ProductPage';
import Header from './components/Header';
import Footer from './components/Footer';
import CheckOut from './components/CheckOut';
import Cart from './components/Cart';
import AccountInfo from './components/AccountInfo';
import OrderHistory from './components/OrderHistory';
import OrderConfirmation from './pages/OrderConfirmation';
import OrderDetails from './pages/OrderDetails';
import ScrollToTop from './components/ScrollToTop';
import CartProvider from './context/CartContext'; // Import the CartProvider
import { AlertProvider } from './context/AlertContext';


function App() {
  const location = useLocation();
  
  const noHeaderFooterRoutes = [];

  return (
    <AlertProvider>
    <CartProvider> {/* Wrap the app with the CartProvider */}
      <div className="app">
        {/* Conditionally render Header */}
        {!noHeaderFooterRoutes.includes(location.pathname) && <Header />}
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/shop/:categoryId" element={<ShopPage />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/account/details" element={<AccountInfo />} />
          <Route path="/account/orders" element={<OrderHistory />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/order-confirmation" element={<OrderConfirmation />} />
          <Route path="/order/:orderId" element={<OrderDetails />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/product/:id" element={<ProductPage />} />
        </Routes>
        {!noHeaderFooterRoutes.includes(location.pathname) && <Footer />}
      </div>
    </CartProvider>
    </AlertProvider>
  );
}

export default App;
