import React from 'react';
import '../css/NoResultsFound.css'; // Ensure your CSS styles are included
import { BsExclamationCircle } from 'react-icons/bs'; // Import Bootstrap Icon


const NoResultsFound = () => {
  return (
    <div className="no-results-container">
      <div className="no-results-icon">
      <BsExclamationCircle className="icon" /> {/* Bootstrap Icon */}
      </div>
      <h2>No Results Found</h2>
      <p>We couldn't find any matches for your search.</p>
    </div>
  );
};

export default NoResultsFound;
