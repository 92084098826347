// src/components/AccountDetails.js
import React, { useContext } from 'react';
import { Tabs, Tab, Container, Row, Col, Button } from 'react-bootstrap';
import AccountInfo from './AccountInfo';

import OrderHistory from './OrderHistory';
import LogoutButton from './LogoutButton';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext
import Loading from './Loading';

const AccountDetails = () => {
  const { currentUser } = useContext(AuthContext); // Access user data from AuthContext
console.log(currentUser);
  return (
    <Container className="mt-4 padding-con" style={{
        minHeight: '90vh'}}>
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <h2 >Welcome, {currentUser.name}!</h2>
            <LogoutButton />
          </div>
          <Tabs defaultActiveKey="details" id="account-details-tabs" className="mb-3">
            <Tab eventKey="details" title="Account Details">
              <AccountInfo />
            </Tab>
          
            <Tab eventKey="orders" title="Order History">
              <OrderHistory />
            </Tab>

          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountDetails;
