import React from 'react';
import '../css/EmptyCart.css'; // Ensure your CSS styles are included
import { Link } from 'react-router-dom';

const EmptyCart = () => {
  return (
    <div className="empty-cart-container">
      <div className="book-bounce">
        <div className="book"></div>
        <div className="book"></div>
        <div className="book"></div>
      </div>
      <h2>Your Bag is Empty!</h2>
      <p>It looks like you haven’t added any books yet.</p>
      <Link to="/shop" className="btn btn-success">
        Browse Books
      </Link>
    </div>
  );
};

export default EmptyCart;
