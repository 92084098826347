import React, { createContext, useState, useContext } from 'react';
import { Alert } from 'react-bootstrap';

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({ show: false, message: '', variant: 'success' });

  const triggerAlert = (message, variant = 'success') => {
    setAlert({ show: true, message, variant });

    // Auto-hide the alert after 3 seconds
    setTimeout(() => {
      setAlert({ ...alert, show: false });
    }, 3000);
  };

  return (
    <AlertContext.Provider value={{ triggerAlert }}>
      {children}

      {/* Global Alert */}
      {alert.show && (
        <Alert
          variant={alert.variant}
          onClose={() => setAlert({ ...alert, show: false })}
          dismissible
          style={{ position: 'fixed', top: '60px', right: '10px', zIndex: '1050', width: '300px' }}
        >
          {alert.message}
        </Alert>
      )}
    </AlertContext.Provider>
  );
};
