// ProductSlider.jsx
import React, { useState } from 'react';
import Slider from 'react-slick';
import ProductCard from './ProductCard'; // Import the ProductCard component
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/ProductSlider.css'; // Custom CSS for slider
import { Col } from 'react-bootstrap';

const ProductSlider = ({ products }) => {
  const [language] = useState(localStorage.getItem('language') || 'en'); // Default to English
 
  const settings = {
    autoplay: true,
    autoplaySpeed: 3000, // Change slide every 3 seconds
    dots: false, // Show navigation dots
    arrows: false, // Show navigation arrows
    slidesToShow: 4,
    slidesToScroll: 1, // Number of cards to scroll per click
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="product-slider">
      <Slider {...settings}>
        {products.map((product) => {
          // Set product name and category based on language
          const productName = language === 'en' ? product.nameEn : product.nameTa;
        
          return (
            <Col md={3} sm={6} xs={6} key={product.id}>
              <ProductCard  
                id={product.id}
                image={product.productImage}
                productName={productName} // Use the dynamic product name
                categoryName={product.categoryName} // Use the dynamic category name
                price={product.price}
                stock={product.stockStatus}
                sku={product.sku}
              />
            </Col>
          );
        })}
      </Slider>
    </div>
  );
};

export default ProductSlider;
