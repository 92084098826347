import { useEffect, useState } from 'react';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import { getAuth, fetchSignInMethodsForEmail } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc, collection, addDoc,orderBy,limit,getDocs,query,generateOrderId} from 'firebase/firestore';
import { Navigate } from 'react-router-dom'; // Import Navigate

const CheckOut = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cartItems, totalCartValue, shippingCharge, grandTotal } = location.state || {};
  const [paymentMethod, setPaymentMethod] = useState(''); // State to handle payment method selection
  
  const auth = getAuth();
  const db = getFirestore();

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    zip: '',
    country: '',
    state: '',
    phone: '',
  });

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false); // State to track order placement

  useEffect(() => {
    const fetchUserDetails = async () => {
      const user = auth.currentUser;
      if (user) {
        setIsLoggedIn(true);
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setFormData({
            fullName: userData.name || '',
            email: user.email,
            addressLine1: userData.address?.line1 || '',
            addressLine2: userData.address?.line2 || '',
            city: userData.address?.city || '',
            state: userData.address?.state || '',
            postalCode: userData.address?.postalCode || '',
            country: userData.address?.country || '',
            phone: userData.phone || '',
          });
        }
      }
    };

    fetchUserDetails();
  }, [auth, db]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
  
    try {
      // Generate a unique order ID
      const orderId = generateOrderId();
      console.log(orderId);
      const orderDetails = {
        orderId, // Add the generated order ID
        userId: user ? user.uid : null,
        items: cartItems.map(item => ({
          id: item.customId,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
        })),
        totalValue: totalCartValue,
        shippingCharge: shippingCharge,
        grandTotal: grandTotal,
        paymentMethod: paymentMethod, // Store selected payment method
        shippingAddress: {
          fullName: formData.fullName,
          email: formData.email,
          phone: formData.phone,
          addressLine1: formData.addressLine1,
          addressLine2: formData.addressLine2,
          city: formData.city,
          postalCode: formData.postalCode,
          country: formData.country,
          state: formData.state,
        },
        createdAt: new Date(),
        status: "Processing"
      };
  
      const ordersCollectionRef = collection(db, 'orders');
      await addDoc(ordersCollectionRef, orderDetails);
  
      // Clear cart items from local storage
    
      
  
      setOrderPlaced(true); 
      navigate('/order-confirmation', { state: { orderDetails } });
  
    } catch (error) {
      console.error('Error placing order:', error);
      alert('Failed to place the order. Please try again.');
    }
  };
  
  const generateOrderId = () => {
    const uniqueId = Date.now().toString();  // Use current timestamp
    return `ORD${uniqueId}`;
  };
  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value); // Set selected payment method
  };


  return (
    <div className="container">
      <br />
      <center>
        <h2>Checkout</h2>
        <hr />
      </center>
      <div className="row">
        <div className="col-md-4">
          <div className="che-con">
            <h4>Order Summary</h4>
            {!orderPlaced ? (
              <>
                <p>Total Cart Value: ₹{totalCartValue?.toFixed(2)}</p>
                <p>Shipping Charges: ₹{shippingCharge?.toFixed(2)}</p>
                <p>Grand Total: ₹{grandTotal?.toFixed(2)}</p>

                <h4>Items:</h4>
                <ul>
                  {cartItems?.map((item) => (
                    <li key={item.customId}>
                      {item.name} - {item.quantity} x ₹{item.price}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <p>Your order has been placed successfully!</p>
            )}
          </div>
        </div>
      
        <div className="col-md-8 padding-con">
          <div className="container che-con">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="fullName">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    required
                    style={{ width: '100%' }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="example@mail.com"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    disabled={isLoggedIn}
                    style={{ width: '100%' }} // Disable email input if user is logged in
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                    style={{ width: '100%' }}
                  />
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="addressLine1">Address line 1</label>
                <input
                  type="text"
                  className="form-control"
                  id="addressLine1"
                  name="addressLine1"
                  value={formData.addressLine1}
                  onChange={handleInputChange}
                  required
                  style={{ width: '100%' }}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="addressLine2">Address line 2</label>
                <input
                  type="text"
                  className="form-control"
                  id="addressLine2"
                  name="addressLine2"
                  value={formData.addressLine2}
                  onChange={handleInputChange}
                  required
                  style={{ width: '100%' }}
                />
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    placeholder="City"
                    value={formData.city}
                    onChange={handleInputChange}
                    required
                    style={{ width: '100%' }}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="postalCode">Zip Code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="postalCode"
                    name="postalCode"
                    placeholder="Zip"
                    value={formData.postalCode}
                    onChange={handleInputChange}
                    required
                    style={{ width: '100%' }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="country">Country</label>
                  <input
                    type="text"
                    className="form-control"
                    id="country"
                    name="country"
                    placeholder="Country"
                    value={formData.country}
                    onChange={handleInputChange}
                    required
                    style={{ width: '100%' }}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="state">State</label>
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    name="state"
                    placeholder="State"
                    value={formData.state}
                    onChange={handleInputChange}
                    required
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="mb-3">
                <label>Payment Method</label>
                
                <div>
                  <input
                    type="radio"
                    id="ccavenue"
                    name="paymentMethod"
                    value="ccavenue"
                    onChange={handlePaymentMethodChange}
                    required
                    defaultChecked
                  />
                  <label htmlFor="ccavenue">CCAvenue (Online Payment)</label>
                </div>
              </div>

              </div>

              <button type="submit" className="btn btn-success">Place Order</button>
            </form>
          </div>
          <br/>
        </div>
      </div>
    </div>
  );
};

export default CheckOut;
