// ProductSlider.jsx
import React,{useState} from 'react';
import Slider from 'react-slick';
import ProductCard from './ProductCard'; // Import the ProductCard component
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/ProductSlider.css'; // Custom CSS for slider
import { Container, Row, Col, Button, Card, Form, Alert } from 'react-bootstrap';

const  RelatedProductSlider = ({ products }) => {
  const [language] = useState(localStorage.getItem('language') || 'en'); // Default to English
  
  const settings = {
    autoplay: false,
    autoplaySpeed: 3000, // Change slide every 3 seconds
    dots: false, // Show navigation dots
    arrows: false, // Show navigation arrows
    slidesToShow: 4,
    slidesToScroll: 1, // Number of cards to scroll per click
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="product-slider">
      <Slider {...settings}>
        {products.map((related) => (
              <Col md={3} sm={6} xs={6}key={related.id}>
            <ProductCard  
              id={related.id}
              image={related.productImage}
              productName= {language === 'en' ? related.nameEn : related.nameTa}
              categoryName={related.categoryName}
              price={related.price}
            />
          </Col>
        ))}
      </Slider>
    </div>
  );
};



export default RelatedProductSlider;
